import classNames from 'classnames';
import {useMemo, useState} from 'react';
import {store} from './mapData';
import {ThreeDots} from 'react-loader-spinner';
import useTextNormalization from '@/hooks/useTextNormalization';
import Accordion from '../accordion';
import {useLanguage} from '@/hooks/LanguageContext';

const MapLists = ({active, handleCenterMap, onClick, handleSetStore, activeStore, data, userLocation = null, isWaiting}) => {
  const {translate} = useLanguage();
  const normalizeText = useTextNormalization();
  const [keyword, setKeyword] = useState('');

  const filteredData = useMemo(() => {
    if (keyword === '') return data;

    const normalizedKeyword = normalizeText(keyword);

    return data.filter(item => {
      const normalizedTitle = normalizeText(item.title);
      const normalizedCity = item.city ? normalizeText(item.city) : '';
      const normalizedTown = item.town ? normalizeText(item.town) : '';

      return normalizedTitle.includes(normalizedKeyword) || normalizedCity.includes(normalizedKeyword) || normalizedTown.includes(normalizedKeyword);
    });
  }, [keyword, data]);

  const generateTitle = () => {
    if (isWaiting) {
      return (
        <span className="text-[#999999] flex items-center">
          <ThreeDots height="24" width="24" radius="9" color="#999999" ariaLabel="three-dots-loading" visible={true} />
          <span className="ml-2 uppercase">{translate('Near Me')}</span>
        </span>
      );
    } else if (userLocation && !isWaiting && filteredData.length !== 0) {
      return <span className="text-[#999999] uppercase">{translate('Near Me')}</span>;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="px-[48px] flex-grow-0 max-lg:px-[16px]">
        <div className="flex justify-between items-center">
          <h1 className="text-[32px] font-[540] text-primary max-lg:text-[24px]">{translate('Stores')}</h1>
          <div className="relative after:content-['\e905'] after:font-iconmoon after:absolute after:pointer-events-none after:right-0 flex items-center">
            <select
              className="appearance-none pr-6 text-[14px] text-right"
              value={activeStore}
              onChange={e => handleSetStore(parseInt(e.target.value))}>
              {store.map(({id, name}) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="relative mt-[16px]">
          <i className="icon search absolute text-[20px] left-[17px] top-[14px]  max-lg:top-[12px]"></i>
          <input
            type="text"
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            className="pl-[48px] w-full h-[48px] max-lg:h-[40px] text-[14px] text-primary rounded-[30px] border-[1px] bg-[#F8F6F7] border-[#D0D1D3] placeholder:text-[#515151]"
            placeholder={translate('Type province, district or store name')}
          />
        </div>
      </div>
      <div className="mt-[24px] flex-1 flex flex-col overflow-hidden">
        <h3 className="text-[14px] flex-grow-0 font-[540] text-[#999999] pl-[48px] max-lg:pl-[16px]">{generateTitle()}</h3>
        <div className="flex flex-col mt-[8px] flex-1 overflow-x-auto">
          {filteredData.length === 0 ? (
            <div className="flex flex-col justify-center items-center">
              <i className="icon store text-[24px] text-primary text-center mt-12 mb-4" />
              <p className="text-primay text-[14px] leading-5 tracking-[0.28px] text-center max-md:w-[293px]">
                {translate('We do not yet have a store in the location you are looking for.')}
              </p>
            </div>
          ) : (
            filteredData.map((store, index) => (
              <>
                <Accordion active={active} store={store} onClick={onClick} />
                <div
                  className={classNames(
                    'px-[48px] border-b-[0.5px] border-[#BBB] py-[20px] max-lg:px-[16px] max-lg:py-[16px] flex justify-between items-center w-full transition-all hover:bg-[#F8F6F7] max-xl:hidden',
                    {
                      '!bg-[#F8F6F7]': active === store.id,
                    },
                  )}
                  key={index}
                  onClick={() => handleCenterMap(store.id)}>
                  <div className="text-[16px] text-primary">
                    <h4 className="font-[540]">{store.title}</h4>
                    <p className="mt-[4px] text-[12px] text-primary">{store.address}</p>
                  </div>
                  <button onClick={() => onClick(store.id)}>
                    <i className="icon info text-primary text-[20px]" />
                  </button>
                </div>
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default MapLists;
